var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "date-range", class: { sm: _vm.size == "sm" } },
    [
      _c("el-date-picker", {
        staticClass: "performance-date-input",
        class: _vm.customBorder ? "custom-border" : "",
        attrs: {
          size: "small",
          align: "left",
          type: "daterange",
          "value-format": "yyyy-MM-dd",
          editable: false,
          clearable: false,
          "range-separator": "-",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
          "picker-options": _vm.rangeDateOption,
        },
        on: { change: _vm.handleChange },
        model: {
          value: _vm.rangeDate,
          callback: function ($$v) {
            _vm.rangeDate = $$v
          },
          expression: "rangeDate",
        },
      }),
      _c("div", { staticClass: "date-input-icon" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }